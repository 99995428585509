<template>
  <div
    :class="[
      sizes[size],
      shadows[shadow],
      roundedList[rounded],
      themes[theme]
    ]"
  >
    <slot />
  </div>
</template>

<script setup>
defineOptions({
  name: 'AtomsCard'
})

defineProps({
  size: {
    type: String,
    default: 'standard',
    validator: value => ['standard', 'md', 'lg', 'xl', 'none', 'default'].includes(value)
  },

  shadow: {
    type: String,
    default: 'standard',
    validator: value => ['standard', 'bordered', 'sm', 'lg', 'xl', 'none'].includes(value)
  },

  rounded: {
    type: String,
    default: 'standard',
    validator: value => ['standard', 'md', 'lg', 'xl', 'none'].includes(value)
  },

  theme: {
    type: String,
    default: 'default',
    validator: value => ['default', 'bordered', 'plain'].includes(value)
  }
})

// variables
const sizes = {
  standard: 'px-7.5 py-5 ',
  md: 'p-4',
  lg: 'p-6',
  xl: 'p-6 md:py-10 md:px-8',
  none: 'px-0 py-0',
  default: ''
}

const shadows = {
  standard: 'shadow-default',
  bordered: 'shadow-bordered',
  sm: 'shadow-sm-1',
  lg: 'shadow-lg',
  xl: 'shadow-xl',
  none: 'shadow-none'
}

const roundedList = {
  standard: 'rounded-2xl',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-2.5xl',
  none: 'rounded-none'
}

const themes = {
  default: 'bg-white',
  bordered: 'border-1 border-gray-400',
  plain: ''
}
</script>
